// components/CustomEdge.js
import React from 'react';
import { getBezierPath } from 'reactflow';

const operators = ['+', '-', 'x', '÷'];

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onOperatorChange = (event) => {
    if (data && data.onChange) {
      data.onChange(id, event.target.value);
    }
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {!data.isTargetDisplay && (
        <foreignObject
          width={40}
          height={40}
          x={labelX - 20}
          y={labelY - 20}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <select 
            className="nodrag"
            onChange={onOperatorChange}
            value={data.operator}
          >
            {operators.map((op) => (
              <option key={op} value={op}>
                {op}
              </option>
            ))}
          </select>
        </foreignObject>
      )}
    </>
  );
}