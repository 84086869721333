import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, { 
  addEdge, 
  applyEdgeChanges,
  applyNodeChanges,
  Background, 
  Controls
} from 'reactflow';
import { Layout, Button, Space, ConfigProvider } from 'antd';
import 'reactflow/dist/style.css';

import NumberNode from './components/NumberNode';
import TotalNode from './components/TotalNode';
import DisplayNode from './components/DisplayNode';
import CustomEdge from './components/CustomEdge';
import RepeaterNode from './components/RepeaterNode';

const { Header, Content } = Layout;

const nodeTypes = {
  number: NumberNode,
  total: TotalNode,
  display: DisplayNode,
  repeater: RepeaterNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const App = () => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const updateNodeData = useCallback((nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id === nodeId) {
          return { 
            ...node, 
            data: { 
              ...node.data, 
              ...newData,
              onChange: node.data.onChange
            } 
          };
        }
        return node;
      })
    );
  }, []);

  const updateEdgeData = useCallback((edgeId, newData) => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) => {
        if (edge.id === edgeId) {
          return { ...edge, data: { ...edge.data, ...newData } };
        }
        return edge;
      })
    );
  }, []);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((params) => {
    const newEdge = {
      ...params,
      type: 'custom',
      data: { operator: '+', onChange: (id, newOperator) => updateEdgeData(id, { operator: newOperator }) }
    };
    setEdges((eds) => addEdge(newEdge, eds));
  }, [updateEdgeData]);

  const addNode = (type) => {
    const newNode = {
      id: `${type}-${Date.now()}`,
      type,
      position: { x: Math.random() * window.innerWidth / 2, y: Math.random() * window.innerHeight / 2 },
      data: { 
        label: type.charAt(0).toUpperCase() + type.slice(1), 
        value: type === 'number' ? '0' : '',
        color: '#' + Math.floor(Math.random()*16777215).toString(16), // Generate random color
        numberType: 'number',
        onChange: (changes) => updateNodeData(newNode.id, changes)
      },
    };
    if (type === 'repeater') {
      newNode.data = {
        ...newNode.data,
        timeUnit: 'months',
        repetitions: 12,
        inputValue: 0,
        growthRate: 0,
        projectedValues: [],
      };
    } else if (type === 'display') {
      newNode.data = {
        ...newNode.data,
        isProjection: false,
        values: [],
        projectedValues: [],
      };
    }
    setNodes((nds) => nds.concat(newNode));
  };

  useEffect(() => {
    const calculateValues = () => {
      const nodeMap = new Map(nodes.map(node => [node.id, node]));
      
      // Calculate totals
      const totalNodes = nodes.filter((node) => node.type === 'total');
      totalNodes.forEach((totalNode) => {
        const incomingEdges = edges.filter((edge) => edge.target === totalNode.id);
        let total = 0;
        incomingEdges.forEach((edge) => {
          const sourceNode = nodeMap.get(edge.source);
          if (sourceNode) {
            const value = parseFloat(sourceNode.data.value) || 0;
            switch (edge.data.operator) {
              case '+': total += value; break;
              case '-': total -= value; break;
              case 'x': total *= value; break;
              case '÷': 
                if (value !== 0) total /= value; 
                else console.warn('Division by zero attempted');
                break;
              default: console.warn(`Unknown operator: ${edge.data.operator}`);
            }
          }
        });
        updateNodeData(totalNode.id, { value: total.toString() });
      });

      // Update repeater nodes
      const repeaterNodes = nodes.filter((node) => node.type === 'repeater');
repeaterNodes.forEach((repeaterNode) => {
  const incomingEdges = edges.filter((edge) => edge.target === repeaterNode.id);
  let inputValue, inputColor, inputLabel, growthRate, growthRateType;
  incomingEdges.forEach((edge) => {
    const sourceNode = nodeMap.get(edge.source);
    if (edge.targetHandle === 'growthRate') {
      growthRate = parseFloat(sourceNode.data.value) || 0;
      growthRateType = sourceNode.data.numberType;
    } else {
      inputValue = parseFloat(sourceNode.data.value) || 0;
      inputColor = sourceNode.data.color;
      inputLabel = sourceNode.data.label;
    }
  });
  if (inputValue !== undefined && growthRate !== undefined) {
    const projectedValues = Array.from({ length: repeaterNode.data.repetitions }, (_, i) => {
      let value;
      if (growthRateType === 'percent') {
        value = inputValue * Math.pow(1 + (growthRate / 100), i);
      } else {
        value = inputValue + growthRate * i;
      }
      return {
        period: i,
        [inputLabel]: { value, color: inputColor }
      };
    });
    updateNodeData(repeaterNode.id, { 
      inputValue, 
      inputColor,
      inputLabel,
      growthRate, 
      growthRateType,
      projectedValues 
    });
  }
});

// Update display nodes
const displayNodes = nodes.filter((node) => node.type === 'display');
displayNodes.forEach((displayNode) => {
  const incomingEdges = edges.filter((edge) => edge.target === displayNode.id);
  if (incomingEdges.length === 1) {
    const sourceNode = nodeMap.get(incomingEdges[0].source);
    if (sourceNode.type === 'repeater') {
      updateNodeData(displayNode.id, { 
        isProjection: true, 
        projectedValues: sourceNode.data.projectedValues,
        timeUnit: sourceNode.data.timeUnit,
        label: `${sourceNode.data.inputLabel} Projection`
      });
    } else {
      const values = [{
        name: sourceNode.data.label, 
        value: parseFloat(sourceNode.data.value) || 0,
        color: sourceNode.data.color
      }];
      updateNodeData(displayNode.id, { isProjection: false, values });
    }
  } else if (incomingEdges.length > 1) {
    // Handle multiple inputs for stacked bar chart
    const values = incomingEdges.map(edge => {
      const sourceNode = nodeMap.get(edge.source);
      return {
        name: sourceNode.data.label,
        value: parseFloat(sourceNode.data.value) || 0,
        color: sourceNode.data.color
      };
    });
    updateNodeData(displayNode.id, { isProjection: false, values });
  } else {
    // Reset display node if it has no incoming edges
    updateNodeData(displayNode.id, { isProjection: false, values: [], projectedValues: [] });
  }
});
    };

    calculateValues();
  }, [nodes, edges, updateNodeData]);

  return (
    <ConfigProvider>
      <Layout style={{ height: '100vh' }}>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Space>
            <Button onClick={() => addNode('number')}>+ Number</Button>
            <Button onClick={() => addNode('total')}>+ Total</Button>
            <Button onClick={() => addNode('display')}>+ Display</Button>
            <Button onClick={() => addNode('repeater')}>+ Repeater</Button>
          </Space>
        </Header>
        <Content>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            fitView
          >
            <Background />
            <Controls />
          </ReactFlow>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default App;