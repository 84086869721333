import React, { useState, useMemo } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { Card, Typography, Popover, Button, Space, Switch, Table } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Tooltip, Legend);

const { Title } = Typography;

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const chartTypes = [
  { key: 'bar', label: 'Bar Chart' },
  { key: 'stackedBar', label: 'Stacked Bar Chart' },
  { key: 'line', label: 'Line Chart' },
  { key: 'pie', label: 'Pie Chart' },
  { key: 'table', label: 'Table' },
];

export default function DisplayNode({ id, data, isConnectable }) {
  const [chartType, setChartType] = useState('bar');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [animationsEnabled, setAnimationsEnabled] = useState(true);
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const currentChartLabel = useMemo(() => {
    return chartTypes.find(type => type.key === chartType)?.label || 'Select Chart';
  }, [chartType]);

  const handleChartTypeChange = (type) => {
    setChartType(type);
    setPopoverVisible(false);
  };

  const processChartData = (rawData) => {
    if (!rawData || !Array.isArray(rawData) || rawData.length === 0) {
      return [];
    }
  
    if (data.isProjection) {
      return rawData.map((item) => {
        const processedItem = { period: item.period };
        Object.keys(item).forEach(key => {
          if (key !== 'period') {
            processedItem[key] = item[key] && typeof item[key].value !== 'undefined' ? item[key].value : 0;
          }
        });
        return processedItem;
      });
    } else {
      return rawData;
    }
  };

  const renderChart = () => {
    const chartData = processChartData(data.isProjection ? data.projectedValues : data.values);

    if (chartData.length === 0) {
      return <div>No data available</div>;
    }

    const labels = chartData.map(item => data.isProjection ? item.period : item.name);
    let datasets = [];

    if (data.isProjection) {
      const keys = Object.keys(chartData[0]).filter(key => key !== 'period');
      datasets = keys.map(key => ({
        label: key,
        data: chartData.map(item => item[key]),
        backgroundColor: data.projectedValues[0][key].color || '#8884d8',
        borderColor: data.projectedValues[0][key].color || '#8884d8',
        fill: chartType === 'area',
      }));
    } else {
      datasets = [{
        label: 'Value',
        data: chartData.map(item => item.value),
        backgroundColor: chartData.map(item => item.color || '#8884d8'),
        borderColor: chartData.map(item => item.color || '#8884d8'),
        fill: chartType === 'area',
      }];
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: animationsEnabled ? 100 : 0, // Faster animation (500ms) or no animation (0ms)
        easing: 'easeOutQuart', // A quicker easing function
      },
      scales: {
        x: {
          title: {
            display: true,
            text: data.isProjection ? (data.timeUnit || 'Period') : 'Name'
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Value'
          }
        }
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: data.label
        },
        tooltip: {
          animation: {
            animation: false  // Reduce tooltip animation duration
          },
          position: 'nearest',  // Use 'nearest' mode for faster positioning
          intersect: false,  // Disable intersection mode for quicker tooltip display
          mode: 'index',  // Use index mode for better performance with multiple datasets
        }
      },
      hover: {
        mode: 'nearest',
        intersect: false,
        animationDuration: 0  // Disable hover animation for instant feedback
      }
    };

    if (chartType === 'stackedBar') {
      options.scales.x.stacked = true;
      options.scales.y.stacked = true;
    }

    switch (chartType) {
      case 'bar':
      case 'stackedBar':
        return <Bar data={{ labels, datasets }} options={options} />;
      case 'line':
      case 'area':
        return <Line data={{ labels, datasets }} options={options} />;
      case 'pie':
        return <Pie data={{ labels, datasets: [datasets[0]] }} options={options} />;
      case 'table':
        const columns = data.isProjection
          ? [
              { title: data.timeUnit || 'Period', dataIndex: 'period', key: 'period' },
              ...Object.keys(chartData[0])
                .filter(key => key !== 'period')
                .map(key => ({
                  title: key,
                  dataIndex: key,
                  key: key,
                  render: (value) => typeof value === 'number' ? value.toFixed(2) : value
                }))
            ]
          : [
              { title: 'Name', dataIndex: 'name', key: 'name' },
              { 
                title: 'Value', 
                dataIndex: 'value', 
                key: 'value',
                render: (value) => typeof value === 'number' ? value.toFixed(2) : value
              },
            ];
        return (
          <Table 
            dataSource={chartData} 
            columns={columns} 
            pagination={false} 
            scroll={{ y: 200 }}
            size="small"
          />
        );
      default:
        return <div>Unsupported chart type</div>;
    }
  };

  const chartTypeSelector = (
    <Space direction="vertical">
      {chartTypes.map((type) => (
        <Button
          key={type.key}
          onClick={() => handleChartTypeChange(type.key)}
          type={chartType === type.key ? 'primary' : 'default'}
        >
          {type.label}
        </Button>
      ))}
      <div>
        Animations: 
        <Switch 
          checked={animationsEnabled} 
          onChange={setAnimationsEnabled} 
          size="small" 
          style={{ marginLeft: '8px' }}
        />
      </div>
    </Space>
  );

  return (
    <Card
      size="small"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title level={5} style={{ margin: 0 }}>{data.label}</Title>
          <Popover
            content={chartTypeSelector}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
            placement="bottomRight"
            overlayStyle={{ zIndex: 1000 }}
          >
            <Button 
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setPopoverVisible(!popoverVisible);
              }}
            >
              {currentChartLabel} ▼
            </Button>
          </Popover>
        </div>
      }
      style={{ width: 300, background: isTarget ? '#e6f7ff' : 'white' }}
    >
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <div style={{ width: '100%', height: 200 }}>
        {renderChart()}
      </div>
    </Card>
  );
}