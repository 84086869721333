// components/NumberNode.js
import React, { memo, useState, useCallback, useRef } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { Card, Typography, Popover, Select, ColorPicker, InputNumber } from 'antd';

const { Title } = Typography;
const { Option } = Select;

const connectionNodeIdSelector = (state) => state.connectionNodeId;

export default memo(({ id, data, isConnectable }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const inputRef = useRef(null);

  const onChange = useCallback((value) => {
    if (value === null || value === undefined) return;
    
    let newValue = value;
    if (data.numberType === 'percent') {
      newValue = value / 100;
    }
    data.onChange({ value: newValue.toString() });
  }, [data]);

  const onTitleChange = useCallback((newTitle) => {
    data.onChange({ label: newTitle });
  }, [data]);

  const onColorChange = useCallback((color) => {
    data.onChange({ color: color.toHexString() });
  }, [data]);

  const onTypeChange = useCallback((newType) => {
    let newValue = parseFloat(data.value);
    if (newType === 'percent' && data.numberType !== 'percent') {
      newValue /= 100;
    } else if (newType !== 'percent' && data.numberType === 'percent') {
      newValue *= 100;
    }
    data.onChange({ numberType: newType, value: newValue.toString() });
  }, [data]);

  const isTarget = connectionNodeId && connectionNodeId !== id;

  const popoverContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <input
        type="text"
        placeholder="Node Title"
        value={data.label}
        onChange={(e) => onTitleChange(e.target.value)}
        style={{ width: '100%' }}
      />
      <ColorPicker value={data.color} onChange={onColorChange} />
      <Select
        value={data.numberType || 'number'}
        onChange={onTypeChange}
        style={{ width: '100%' }}
      >
        <Option value="number">Number</Option>
        <Option value="percent">Percent</Option>
        <Option value="currency">Currency</Option>
      </Select>
    </div>
  );

  const formatter = useCallback((value) => {
    if (data.numberType === 'currency') {
      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (data.numberType === 'percent') {
      return `${value}%`;
    }
    return value;
  }, [data.numberType]);

  const parser = useCallback((value) => {
    if (data.numberType === 'currency') {
      return value.replace(/\$\s?|(,*)/g, '');
    }
    if (data.numberType === 'percent') {
      return value.replace('%', '');
    }
    return value;
  }, [data.numberType]);

  const getDisplayValue = useCallback(() => {
    const numValue = parseFloat(data.value);
    return data.numberType === 'percent' ? numValue * 100 : numValue;
  }, [data.value, data.numberType]);

  const preventDragHandler = useCallback((e) => {
    e.stopPropagation();
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      e.preventDefault();
    }
  }, []);

  return (
    <Card
      size="small"
      title={
        <Popover
          content={popoverContent}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        >
          <Title level={5} style={{ margin: 0, cursor: 'pointer' }}>
            {data.label || 'Edit Node'}
          </Title>
        </Popover>
      }
      style={{ 
        width: 200, 
        background: isTarget ? '#e6f7ff' : 'white',
        borderColor: data.color
      }}
    >
      <div 
        className="nodrag"
        onMouseDown={preventDragHandler}
        onTouchStart={preventDragHandler}
      >
        <InputNumber
          ref={inputRef}
          style={{ width: '100%' }}
          value={getDisplayValue()}
          onChange={onChange}
          formatter={formatter}
          parser={parser}
          precision={data.numberType === 'percent' ? 2 : undefined}
          step={data.numberType === 'percent' ? 1 : 1}
        />
      </div>
      <Handle type="source" position={Position.Right} id="a" isConnectable={isConnectable} />
    </Card>
  );
});