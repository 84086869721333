import React, { memo, useState, useEffect, useCallback } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { Card, Typography, Popover, Select, InputNumber } from 'antd';

const { Title } = Typography;
const { Option } = Select;

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const timeUnitOptions = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
];

export default memo(({ id, data, isConnectable }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const connectionNodeId = useStore(connectionNodeIdSelector);

  const onTitleChange = useCallback((newTitle) => {
    data.onChange({ label: newTitle });
  }, [data]);

  const onTimeUnitChange = useCallback((newUnit) => {
    data.onChange({ timeUnit: newUnit });
  }, [data]);

  const onRepetitionsChange = useCallback((newRepetitions) => {
    data.onChange({ repetitions: newRepetitions });
  }, [data]);

  useEffect(() => {
    const calculateProjectedValues = () => {
      if (data.inputValue !== undefined && data.growthRate !== undefined) {
        const projectedValues = Array.from({ length: data.repetitions }, (_, i) => {
          let totalValue;
          let gainValue;
          if (data.growthRateType === 'percent') {
            totalValue = data.inputValue * Math.pow(1 + (data.growthRate / 100), i);
            gainValue = totalValue - data.inputValue;
          } else {
            totalValue = data.inputValue + data.growthRate * i;
            gainValue = data.growthRate * i;
          }
          return {
            period: i,
            total: { value: totalValue, color: data.inputColor },
            [data.inputLabel]: { value: data.inputValue, color: data.inputColor },
            [`${data.inputLabel} Gain`]: { value: gainValue, color: data.inputColor }
          };
        });
        data.onChange({ 
          projectedValues,
          inputColor: data.inputColor,
          inputLabel: data.inputLabel
        });
      }
    };

    calculateProjectedValues();
  }, [data, data.inputValue, data.growthRate, data.repetitions, data.growthRateType, data.inputColor, data.inputLabel]);

  const isTarget = connectionNodeId && connectionNodeId !== id;

  const popoverContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <input
        type="text"
        placeholder="Node Title"
        value={data.label}
        onChange={(e) => onTitleChange(e.target.value)}
        style={{ width: '100%' }}
      />
      <Select
        value={data.timeUnit}
        onChange={onTimeUnitChange}
        style={{ width: '100%' }}
      >
        {timeUnitOptions.map(option => (
          <Option key={option.value} value={option.value}>{option.label}</Option>
        ))}
      </Select>
      <InputNumber
        min={1}
        max={100}
        value={data.repetitions}
        onChange={onRepetitionsChange}
        style={{ width: '100%' }}
      />
    </div>
  );

  return (
    <Card
      size="small"
      title={
        <Popover
          content={popoverContent}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        >
          <Title level={5} style={{ margin: 0, cursor: 'pointer' }}>
            {data.label || 'Repeater'}
          </Title>
        </Popover>
      }
      style={{ 
        width: 200, 
        background: isTarget ? '#e6f7ff' : 'white',
      }}
    >
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <Handle type="target" position={Position.Top} id="growthRate" isConnectable={isConnectable} />
      <div>Time Unit: {data.timeUnit}</div>
      <div>Repetitions: {data.repetitions}</div>
      <Handle type="source" position={Position.Right} id="output" isConnectable={isConnectable} />
    </Card>
  );
});