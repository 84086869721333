// components/TotalNode.js
import React, { memo, useState, useEffect } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import { Card, Typography, Popover, Select, ColorPicker, Input } from 'antd';
import CountUp from 'react-countup';

const { Title } = Typography;
const { Option } = Select;

const connectionNodeIdSelector = (state) => state.connectionNodeId;

export default memo(({ id, data, isConnectable }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [prevValue, setPrevValue] = useState(parseFloat(data.value) || 0);
  const connectionNodeId = useStore(connectionNodeIdSelector);

  const onTitleChange = (newTitle) => {
    data.onChange({ label: newTitle });
  };

  const onColorChange = (color) => {
    data.onChange({ color: color.toHexString() });
  };

  const onTypeChange = (newType) => {
    data.onChange({ numberType: newType });
  };

  useEffect(() => {
    setPrevValue(parseFloat(data.value) || 0);
  }, [data.value]);

  const isTarget = connectionNodeId && connectionNodeId !== id;

  const popoverContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Input
        placeholder="Node Title"
        value={data.label}
        onChange={(e) => onTitleChange(e.target.value)}
      />
      <ColorPicker value={data.color} onChange={onColorChange} />
      <Select
        value={data.numberType || 'number'}
        onChange={onTypeChange}
        style={{ width: '100%' }}
      >
        <Option value="number">Number</Option>
        <Option value="percent">Percent</Option>
        <Option value="currency">Currency</Option>
      </Select>
    </div>
  );

  const formatValue = (value) => {
    switch (data.numberType) {
      case 'percent':
        return { value: value * 100, prefix: '', suffix: '%' };
      case 'currency':
        return { value, prefix: '$', suffix: '' };
      default:
        return { value, prefix: '', suffix: '' };
    }
  };

  const { value, prefix, suffix } = formatValue(parseFloat(data.value));

  return (
    <Card
      size="small"
      title={
        <Popover
          content={popoverContent}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        >
          <Title level={5} style={{ margin: 0, cursor: 'pointer' }}>
            {data.label || 'Edit Node'}
          </Title>
        </Popover>
      }
      style={{ 
        width: 200, 
        background: isTarget ? '#e6f7ff' : 'white',
        borderColor: data.color
      }}
    >
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <CountUp
        start={prevValue}
        end={value}
        decimals={2}
        duration={1}
        separator=","
        prefix={prefix}
        suffix={suffix}
      />
      <Handle type="source" position={Position.Right} id="a" isConnectable={isConnectable} />
    </Card>
  );
});